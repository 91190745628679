import React from 'react';
import Helmet from 'react-helmet';
import Navigation from '../components/WaveNavigation/';

import freight404 from '../assets/img/404Freight.png';

export default () => (
  <div>
    <Helmet
      title="Fire Island Ferries | 404"
      meta={[
        {
          name: 'description',
          content:
            'Fire Island Ferries updates, service notifications, and new schedules.',
        },
        {
          name: 'keywords',
          content: 'Fire Island Ferries, Water Taxi, notifications, updates',
        },
      ]}
    />
    <Navigation />
    <div className="container page text-center">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <img src={freight404} alt="404 Page Not Found" />
        </div>
      </div>
      <h1>Oops. Captain, we seem to be lost!</h1>
      <p>We couldn't find the page you were looking for.</p>
    </div>
  </div>
);
